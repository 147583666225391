import React from 'react';
import { withPrefix } from "gatsby";
import Section from '../Layout/Section/Section'
import InnerContainer from '../Layout/InnerContainer/InnerContainer';
import CoverImage from '../../components/CoverImage';
import Carousel from "../Carousel/Carousel";
// import renderStackedHeadline from '../Utils/RenderStackedHeadline';
// import Slider from "react-slick";

// import "slick-carousel/slick/slick.css";
// import '../Carousel/carousel.scss';
import './large-editorial.scss';

const LargeEditorial = ({ id, image, copy, video, videoSlides, variant }) => {
  if (!variant) {
    if (copy && copy.scriptHeadline) {
      variant="large-editorial--with-cover";
    } else {
      variant="large-editorial";
    }
  }

  function renderHeadlines(headlines) {
    let headlinesHTML = [];
    
    if(headlines && headlines[0]) {
      headlines.forEach ((headline, i) => {
        // console.log("headline: ", headline);
        headlinesHTML.push(<span key={i} className="headline multi-line">{headline.line}</span>)
      })
    }

    return headlinesHTML;
  }

  function renderCopy(block) {
    const copyHTML = [];

    if (block.headline != null) {
      copyHTML.push(
        <h3 key="headline" className="headline animate-on-scroll">{block.headline}</h3>
      );
    }

    {block.headlines ? copyHTML.push(renderHeadlines(block.headlines)) :  ''}

    if (block.body) {
      block.body.map((line, i) => {
        copyHTML.push(
          <p key={"body-"+i} className="body-copy">{line.line}</p>
        )
      })
    }
    
    return copyHTML;
  }

  function renderCoverImage(image, video, copy, variant) {
    if (image || video) {
      return CoverImage({variant, image, video, copy})
    }
  }
    
  function renderBullets(block, key) {
    const listItems = [];
      
    if (block.bullets && block.bullets[0].bullet !== "") {
      block.bullets.map((item, i) => {
        return listItems.push(
          <li key={key+i} className="animate-on-scroll">{item.bullet}</li>
        )
      })
      
      return <ul className="body-copy">{listItems}</ul>;
    }
  }


  function renderBlocks(copy) {
    const allBlocksHTML = [];
    
    if (copy.blocks) {
      copy.blocks.forEach((block, i) => {
        const blockHTML = [];
        if (block.headline != null || block.headlines != null || (block.body && block.body[0].line != null) || (block.bullets && block.bullets[0].bullet !== "")) {
          blockHTML.push(
            <div key={i} className="block animate-on-scroll">
              {renderCopy(block, "copy-")}
              {renderBullets(block, "bullets-")}
            </div>
          )
        }
  
        allBlocksHTML.push(blockHTML);
      })
  
      return allBlocksHTML;
    }
  }

  return (
    <>
      <Section id={id} customClass={["large-editorial", variant]}>
        {renderCoverImage(image, video, copy, variant )}
        {videoSlides ? <Carousel videoSlides={videoSlides}/> : ''}
        {(copy && copy.headline) ? <p key="subhead" className="subhead animate-on-scroll">{copy.headline}</p> : ''}
        {(copy && copy.blocks) ? 
        <InnerContainer customClass="copy-blocks">
          {renderBlocks(copy)}
        </InnerContainer>
        : ''}
      </Section>
    </>
  )
}

export default LargeEditorial
