import React, { useEffect } from "react";
import { graphql, withPrefix, useStaticQuery } from "gatsby";
import Section from '../Layout/Section/Section';
import PreviewCompatibleImage from '../Utils/PreviewCompatibleImage';
import RenderStackedHeadline from  '../Utils/RenderStackedHeadline';
import RenderBlueFrameOverlay from '../Utils/RenderBlueFrameOverlay';

import "./cover-image.scss"

const CoverImage = ({ id, variant, image, copy, video, logo}) => {

  function renderImageOrVideo(image, video, variant) {    
    if (image) {
      return <>
      {(variant === 'blue-frame' || 'hero') ? RenderBlueFrameOverlay() : ''}
      <PreviewCompatibleImage customClass="cover__image" imageInfo={image} alt="cover image" />
      </>
    } else if (video) {
      const videoPoster = video.poster ? getPosterFrameURL(video.poster) : '';
      const videoURL = video.src.includes("http") ? video.src : withPrefix('/' + video.src);
      return <video controls={video.controls} width="100%" preload="metadata" autoPlay={video.autoplay} muted={video.autoplay} poster={videoPoster} loop={video.loop}><source src={videoURL}  type="video/mp4" /><track/></video>
    }
  }

  function getPosterFrameURL(posterFrame) {
    return posterFrame.includes("http") ? posterFrame : withPrefix('/' + posterFrame);
  }

  const maybeRenderCaption = (copy) => {
    if (copy && copy.caption) {
      return <span className="caption">{copy.caption}</span> 
    }
  }

  return (
    <Section id={id} customClass={["cover-container", variant]}>
      <span className={`block animate-on-scroll ${logo ? "with-logo" : ''}`}>
        {copy ? RenderStackedHeadline(copy) : ''}
        {logo ? <PreviewCompatibleImage customClass="partner-logo" imageInfo={logo} alt="partner logo" />: ''}
      </span>
      {renderImageOrVideo(image, video, variant)}
      {maybeRenderCaption(copy)}
    </Section>
  )
};

export default CoverImage;
