import React, { useEffect, useRef } from 'react';
import { withPrefix } from "gatsby";
import Section from '../Layout/Section/Section';
import InnerContainer from '../Layout/InnerContainer/InnerContainer';
import PreviewCompatibleImage from '../Utils/PreviewCompatibleImage';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import './carousel.scss';

const Carousel = (...props) => {
  const prevArrowHTML = <svg viewBox="0 0 30 30"><path d="M7.61,14.58,20.88,1.31a1,1,0,0,1,1.46,1.46L9.85,15.26l12,12a1,1,0,0,1-1.46,1.46L7.66,16a1,1,0,0,1-.31-.73A1,1,0,0,1,7.61,14.58Z"/></svg>
  const nextArrowHTML = <svg viewBox="0 0 30 30"><path d="M22.65,15.26a1,1,0,0,1-.31.73L9.64,28.69a1,1,0,0,1-1.46-1.46l12-12L7.66,2.77A1,1,0,0,1,9.12,1.31L22.39,14.58A1,1,0,0,1,22.65,15.26Z"/></svg>
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: prevArrowHTML,
    nextArrow: nextArrowHTML,
    accessibility: true,
    beforeChange: (current, next) => beforeChangeEvent(current, next)
    // afterChange: (current, next) => afterChangeEvent(current, next)
  };

  const slider = useRef();

  useEffect(() => {
    slider.current.slickGoTo(props[0].currentSlide, false);
  });

  function beforeChangeEvent(current, next) {
    const videos = Array.from(slider.current.innerSlider.list.childNodes[0].getElementsByTagName('video'));
    
    if (videos.length > 0) {
      videos.forEach(video => {
        if(video.paused !== true) {
          video.pause();
        };
      })
    }
  }

  function renderSlides(props) {
    // console.log("props.currSlide", props.currentSlide)
    let carouselHTML = [];
    const {videoSlides} = props;
    
    if (videoSlides) {
      videoSlides.forEach((videoData, i) => {
        if (videoData.still || videoData.image ) {
          const imageInfo = videoData.still || videoData.image;
          carouselHTML.push(
            <React.Fragment key={i}>
            <PreviewCompatibleImage imageInfo={imageInfo} alt="" />
            {videoData.caption ? <p className="caption">{videoData.caption}</p> : ''}
            </React.Fragment>
          )
        } else if (videoData.video) {
          const videoURL = videoData.video.src.includes("http") ? videoData.video.src : withPrefix('/' + videoData.video.src);
          const videoPoster = videoData.video.poster ? getPosterFrameURL(videoData.video.poster) : '';
          console.log(videoPoster);
          carouselHTML.push(
            <React.Fragment key={i}>
            <video controls={videoData.video.controls} width="100%" preload="metadata" autoPlay={videoData.video.autoplay} loop={videoData.video.loop} poster={videoPoster}>
              <source src={videoURL}  type="video/mp4" /><track/>
            </video>
            {videoData.video.caption ? <p className="caption">{videoData.video.caption}</p> : ''}
            </React.Fragment>
          );
        } else {
          return null
        }
      })
    }
    return <Slider ref={slider} {...settings}>{carouselHTML}</Slider>;
  }

  function getPosterFrameURL(posterFrame) {
    return posterFrame.includes("http") ? posterFrame : withPrefix('/' + posterFrame);
  }

  return (
    <InnerContainer customClass="carousel-component">
      {renderSlides(...props)}
    </InnerContainer>
  )
}

export default Carousel;
