import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import PreviewCompatibleImage from './PreviewCompatibleImage';

const RenderBlueFrameOverlay = (variant) => {
  const brushFrameImageData = useStaticQuery(graphql`
    query brushFrameImage {
      file(relativePath: { regex: "/brush-frame.png/" }) {
        childImageSharp {
          fluid(maxWidth: 2048, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <PreviewCompatibleImage customClass="brush-frame" imageInfo={brushFrameImageData.file} alt="brush frame" />
}

export default RenderBlueFrameOverlay;
