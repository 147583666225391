import React from 'react';
import PreviewCompatibleImage from '../Utils/PreviewCompatibleImage';
import RenderStackedHeadline from '../Utils/RenderStackedHeadline';
import TextBlock from '../TextBlock/TextBlock';
import RenderPressQuoteText from  '../../components/Utils/RenderPressQuoteText';

// import Section from '../Layout/Section/Section';
// import InnerContainer from '../Layout/InnerContainer/InnerContainer';

import './press-quotes.scss'
const renderPressQuotes = (quotes) => {
  let pressQuotesHTML = []
  
  
  quotes.map((quote, i) => {    
    let boxVariant;
    if (quote.line.length > 200) {
      boxVariant = 'press-quote-container--wide';
    } else if (quote.line.length > 160) {
      boxVariant = 'press-quote-container--medium';
    } else {
      boxVariant = '';
    }
    // console.log(quote.line.length, boxVariant)
    pressQuotesHTML.push(
      <div key={i} className={`press-quote-container ${boxVariant} press-quote-container--${++i} animate-on-scroll`}>
        {RenderPressQuoteText(quote)}
      </div>
    )
  })
  return pressQuotesHTML;
}

const PressQuotes = ({copy}) => {
  let pressHTML = []
  
  if(!copy) {
    return null
  }

  pressHTML.push(
    <section key={0} className="press-quotes">
      {renderPressQuotes(copy.quotes)}
    </section>
  )
  
  // pressQuotesHTML.push(<PreviewCompatibleImage imageInfo={image} />)
  return pressHTML;
}


// function renderPressQuotes(quotes) {
//   const pressQuotes = [];
//   quotes.forEach((quote, i) => {
//     i++
//     pressQuotes.push(
//       <div key={i} id={i} className={`press-quote ${quote.line.length > 125 ? 'wide' : ''} press-quote--${i}` }>
//         <p className="press-quote__body"><q>{quote.line}</q></p>
//         <p className="press-quote__source">{quote.source}</p>
//       </div>
//     )
//   })
//   return pressQuotes;
// }

// const PressQuotes = ({copy}) => {
//   return (
//     <Section>
//       <InnerContainer customClass="press-quotes-container">
//         {renderPressQuotes(copy.quotes)}
//       </InnerContainer>
//     </Section>
//   )
// }

export default PressQuotes
